import React from "react"

import Layout from "../layout"
import SEO from "../components/seo"
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

import Hero from "../components/heros/catering"
import { relative } from "path";
import { hidden } from "ansi-colors";

const SecondPage = () => (
  <Layout>
    <SEO title="Location" />

    <Hero>
      <Container fixed style={{ textAlign: 'center', paddingTop: '4rem' }}>
        <Typography variant="h1" style={{ marginBottom: '2rem' }}>
          Location
        </Typography>
      </Container>
    </Hero>

    <Container fixed style={{ paddingTop: '4rem', paddingBottom: '4rem' }}>
      <div style={{position: relative, overflow: hidden }}>
        <iframe width="800" height="600" frameborder="0" style={{ border: 0, width: '100%', height: '100%', minHeight: '50rem' }} allowfullscreen="" title='map' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d24561.15703131242!2d-104.987099!3d39.691452!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd1468412e2aa8c0e!2sCluck%20Chicken%20at%20The%20Dive%20Inn!5e0!3m2!1sen!2sus!4v1575674645288!5m2!1sen!2sus"></iframe>
      </div>
    </Container>
  </Layout>
)

export default SecondPage
